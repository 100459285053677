import AccountMain from "../Components/AccountMain";
import BottomNavigationArea from "../Components/BottomNavigation";

const Account = () => {
  return (
    <div>
      <AccountMain>
        <BottomNavigationArea />
      </AccountMain>
    </div>
  );
};

export default Account;
