import React from "react";
import MessagesMain from "../Components/MessagesMain";

const Messages = () => {
  return (
    <div>
      <MessagesMain>
      </MessagesMain>
    </div>
  );
};

export default Messages;
