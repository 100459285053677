import React from "react";
import { Container } from "@mui/material";
import Mobile from "../Components/Mobile";

const OpenPageMain = () => {
  return (
    <Mobile>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#0F6518",
          color: "white",
          textAlign: "center",
        }}
      >
        <img
          src="assets/start-aa57f240.webp"
          alt="Main"
          style={{
            width: "100%",
            marginTop: 130,
          }}
        />

        <img
          src="assets/anotechLogo_white.webp"
          alt="Logo"
          style={{
            width: 200,
            marginTop: 50,
          }}
        />
      </Container>
    </Mobile>
  );
};

export default OpenPageMain;
