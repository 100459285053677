import ActivityMain from "../Components/ActivityMain";
import BottomNavigationArea from "../Components/BottomNavigation";

const Activity = () => {

  return (
    <div>
      <ActivityMain>
        <BottomNavigationArea />
      </ActivityMain>
    </div>
  );
};

export default Activity;
